import Controller from './controller'

export default class Recaptcha extends Controller {
  initialize() {
    this.form = this.element.closest('form')

    if (!this.form) {
      console.warn('Recaptcha without form ancestor', this.element)
      return
    }

    this.onInteraction = this.onInteraction.bind(this)

    // Defer loading reCAPTCHA API JS until the visitor interacts with this form
    this.form.addEventListener('focusin', this.onInteraction)
  }

  onInteraction() {
    if (this.hasReceivedInteraction) return
    this.hasReceivedInteraction = true

    this.form.removeEventListener('focusin', this.onInteraction)

    if (!Recaptcha.isApiLoading) {
      Recaptcha.loadApi()
    }

    this.form.addEventListener('submit', (ev) => {
      const input = this.form.querySelector('.g-recaptcha-response')

      if (!input?.value.trim()) {
        ev.preventDefault()
        ev.stopImmediatePropagation()
        alert('Please complete the reCAPTCHA')
      }
    })
  }
}

Recaptcha.loadApi = () => {
  Recaptcha.isApiLoading = true

  window.onRecaptchaApiReady = () => {
    document.querySelectorAll('[data-controller*="recaptcha"] .g-recaptcha').forEach((element) => {
      window.grecaptcha.render(element, {
        sitekey: element.getAttribute('data-sitekey'),
        callback: (token) => {
          const input = element.closest('form').querySelector('.g-recaptcha-response')
          input.value = token
        },
      })
    })
  }

  const recaptchaScript = document.createElement('script')
  recaptchaScript.src =
    'https://www.google.com/recaptcha/api.js?onload=onRecaptchaApiReady&render=explicit'
  recaptchaScript.async = true
  document.head.appendChild(recaptchaScript)
}
