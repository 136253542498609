import Controller from './controller'
import { scrollToPosition } from '../utilities'

export default class extends Controller {
  static targets = ['content', 'link', 'toggle']

  static values = {
    smoothScroll: Boolean,
  }

  initialize() {
    this.selectedClass = 'is-selected'
    this.next = this.next.bind(this)
    this.hide = this.hide.bind(this)
    this.previous = this.previous.bind(this)
    this.hash420 = this.linkTargets[0].hasAttribute('data-hash')

    const selectedLink = this.linkTargets.find((link) =>
      link.classList.contains(this.selectedClass),
    )
    if (selectedLink) {
      this.selectedIndex = this.linkTargets.indexOf(selectedLink)
    }

    if (this.hash420) {
      this.hashCheck()
    }
  }

  hashCheck() {
    const hash = window.location.hash.substr(1)

    for (let i = 0; i < this.linkTargets.length; i++) {
      if (this.linkTargets[i].getAttribute('data-hash') === hash) {
        this.go(i)
      }
    }
  }

  previous() {
    if (this.selectedIndex - 1 < 0) {
      this.go(this.linkTargets.length - 1)
    } else {
      this.go(this.selectedIndex - 1)
    }
  }

  next() {
    if (this.selectedIndex + 1 > this.linkTargets.length - 1) {
      this.go(0)
    } else {
      this.go(this.selectedIndex + 1)
    }
  }

  select(ev) {
    ev.preventDefault()

    const index = this.linkTargets.indexOf(ev.currentTarget)

    this.go(index)
  }

  hide() {
    this.linkTargets.forEach((elem, index) => elem.classList.remove(this.selectedClass))
    this.contentTargets.forEach((elem, index) => elem.classList.remove(this.selectedClass))
  }

  toggle(ev) {
    ev.preventDefault()

    const index = this.toggleTargets.indexOf(ev.currentTarget)

    if (index === this.selectedIndex) {
      this.hide()
      this.selectedIndex = -1
    } else {
      this.go(index)
    }
  }

  go(index) {
    this.emit('tabs:beforechange', { bubbles: true })

    this.hide()

    this.linkTargets[index].classList.add(this.selectedClass)
    this.contentTargets[index].classList.add(this.selectedClass)

    if (this.hash420) {
      window.location.hash = this.linkTargets[index].getAttribute('data-hash')
    }

    if (this.element.hasAttribute('data-scroll') && index != this.selectedIndex) {
      setTimeout(() => {
        this.scrollToTarget(this.contentTargets[this.selectedIndex])
      }, 0)
    }

    this.selectedIndex = index
  }

  scrollToTarget(element) {
    // Not sure this works in IE. Added as a quick temp fix for the staging demo.
    element.scrollIntoView({
      block: 'start',
    })

    element.setAttribute('tabindex', '-1')
    element.focus({ preventScroll: true })
  }

  scrollToTop() {
    const top = this.element.getBoundingClientRect().top + 2 // Extra to cover tab border

    if (top < 2) {
      scrollToPosition({
        top: window.pageYOffset + top,
        behavior: this.smoothScrollValue ? 'smooth' : 'auto',
      })
    }
  }
}
