import Controller from './controller'
import Modal from './modal_controller'

export default class extends Controller {
  open(ev) {

    this.id ||= this.data.get('id')

    // If this modal link is inside a modal, close that modal when opening this new one
    const modalTarget = this.element.closest('[data-controller="modal"]')
    if (modalTarget) {
      const modal = this.application.getControllerForElementAndIdentifier(modalTarget, 'modal')
      modal.close()
    }

    // If this modal has been opened before, reopen it
    if (Modal.instances[this.id]) {
      Modal.instances[this.id].open()
    } else {
      this.createModal()
    }
  }

  createModal() {
    const template = this.getTemplateTarget()

    if (!template) {
      console.warn(`Modal template missing: ${this.id}`)
      return
    }

    this.modalTarget = document.importNode(template.content, true).firstElementChild
    this.modalTarget.dataset.modalId = this.id
    this.modalTarget.id ||= `modal-${this.id}`

    // Modals open automatically when Stimulus finds them in the DOM (see modal_controller)
    document.body.appendChild(this.modalTarget)
  }

  getTemplateTarget() {
    return document.querySelector(`template[data-modal-id='${this.id}']`)
  }
}
