import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['name', 'output']

  initialize() {
    console.log('Example controller has initialized')
  }

  greet(ev) {
    ev.preventDefault()

    const name = this.nameTarget.value.trim()

    if (name) {
      this.outputTarget.textContent = `Hello, ${name}!`
    } else {
      alert('Your name, please')
    }
  }
}
