import Controller from './controller'
import { scrollToPosition } from '../utilities'

export default class extends Controller {
  scroll(ev) {
    const navEl = document.getElementById('navigation')
    ev.preventDefault()

    if (!this.target) {
      console.warn('Scroll target not found', this.element)
      return
    }

    scrollToPosition({
      top: window.pageYOffset + this.target.getBoundingClientRect().top - navEl.offsetHeight,
      includeHeaderOffset: false,
    })
  }

  get target() {
    if (!this._target) {
      // e.g. `data-scroll-to-target="#element"` or `href="#element"`
      const selector = this.data.get('selector') || this.element.hash

      if (selector) {
        this._target = document.querySelector(selector)
      }
    }

    return this._target
  }
}
