import Controller from './controller'
import { elementId } from '../utilities'

export default class extends Controller {
  static targets = ['content', 'toggle']

  static values = {
    mouse: Boolean,
  }

  get focusables() {
    if (!this.cachedFocusables) {
      this.cachedFocusables = Array.from(this.contentTarget.querySelectorAll('a')).filter(
        (link) => link.offsetWidth > 0,
      )
    }

    return this.cachedFocusables
  }

  initialize() {
    this.onClick = this.onClick.bind(this)
    this.onKeydown = this.onKeydown.bind(this)
    this.onMouseenter = this.onMouseenter.bind(this)
    this.onMouseleave = this.onMouseleave.bind(this)
    this.resize = this.resize.bind(this)

    this.hoverOpenDelay = 125 // Time after mouseenter before showing dropdown
    this.hoverCloseDelay = 250 // Time after mouseleave before hiding dropdown
    this.hoverQuickSwitchDelay = 50 // When switching between dropdowns - time after mouseenter before showing dropdown

    this.elementOpenClass = 'is-open'

    if (!this.toggleTarget.id) this.toggleTarget.id = elementId()
    if (!this.contentTarget.id) this.contentTarget.id = elementId()
    this.toggleTarget.setAttribute('aria-haspopup', 'true')
    this.toggleTarget.setAttribute('aria-controls', this.contentTarget.id)
    this.contentTarget.setAttribute('aria-labelledby', this.toggleTarget.id)

    this.toggleTarget.addEventListener('click', (ev) => {
      ev.preventDefault()
      this.toggle()
    })

    this.resize()
    window.addEventListener('resize', this.resize)
  }

  resize() {
    // If this.element has ::before content set to 'touch', don't enable mouse interaction
    const isTouch =
      window
        .getComputedStyle(this.element, '::before')
        .getPropertyValue('content')
        .replace(/['"]+/g, '') === 'touch'

    // Open dropdown on hover if this.element has data-dropdown-mouse-value="true" set
    if (this.mouseValue && !isTouch) {
      this.enableMouse()
    } else {
      this.disableMouse()
    }
  }

  enableMouse() {
    if (this.isMouseEnabled) return
    this.isMouseEnabled = true

    this.element.addEventListener('mouseenter', this.onMouseenter)
    this.element.addEventListener('mouseleave', this.onMouseleave)
  }

  disableMouse() {
    if (!this.isMouseEnabled) return
    this.isMouseEnabled = false

    this.element.removeEventListener('mouseenter', this.onMouseenter)
    this.element.removeEventListener('mouseleave', this.onMouseleave)
  }

  open() {
    clearTimeout(this.closeTimeout)

    if (this.isOpen) return
    this.isOpen = true

    this.emit('dropdown:open', { bubbles: true, detail: { dropdown: this } })

    this.element.classList.add(this.elementOpenClass)
    this.toggleTarget.setAttribute('aria-expanded', 'true')

    window.addEventListener('click', this.onClick)
    window.addEventListener('keydown', this.onKeydown)
  }

  close() {
    if (!this.isOpen) return
    this.isOpen = false

    this.isHoverOpened = false

    this.emit('dropdown:close', { bubbles: true, detail: { dropdown: this } })

    this.element.classList.remove(this.elementOpenClass)
    this.toggleTarget.removeAttribute('aria-expanded')

    window.removeEventListener('click', this.onClick)
    window.removeEventListener('keydown', this.onKeydown)
  }

  toggle(ev) {
    if (ev) {
      ev.preventDefault()
    }

    if (ev && this.isHoverOpened) {
      this.isHoverOpened = false
    } else if (this.isOpen) {
      this.close()
    } else {
      this.open()
    }
  }

  // Reduce hover delay when switching from an already open dropdown to another
  setQuickSwitchMode() {
    this.quickSwitchMode = true
  }

  unsetQuickSwitchMode() {
    this.quickSwitchMode = false
  }

  onFocusout(ev) {
    if (ev.relatedTarget && !this.element.contains(ev.relatedTarget)) {
      // Timeout to support switching quickly between dropdowns on click. Without this, this dropdown's
      // `dropdown:close` event will be fired before next dropdown's `dropdown:open`, causing both to transition
      this.closeTimeout = setTimeout(() => {
        this.close()
      }, 175)
    }
  }

  onClick(ev) {
    if (!this.contentTarget.contains(ev.target) && !this.toggleTarget.contains(ev.target)) {
      this.close()
    }
  }

  onElementKeydown(ev) {
    const up = ev.key === 'ArrowUp'
    const down = ev.key === 'ArrowDown'

    if (!up && !down) {
      return
    }

    // Prevent default arrow action of scrolling the page.
    ev.preventDefault()

    // Switch focus to next/previous focusable element.
    const index = this.focusables.indexOf(ev.target)
    const { length } = this.focusables
    const nextFocusable = this.focusables[(index + (down ? 1 : -1) + length) % length]

    if (nextFocusable) {
      const focus = () => {
        nextFocusable.focus()
      }

      if (this.isOpen) {
        focus()
      } else {
        this.open()
        setTimeout(focus, 100)
      }
    }
  }

  onKeydown(ev) {
    if (ev.key === 'Escape') {
      this.close()
    }
  }

  onMouseenter() {
    clearTimeout(this.mouseleaveTimeout)

    if (!this.isMouseEnabled) return

    const delay = this.quickSwitchMode ? this.hoverQuickSwitchDelay : this.hoverOpenDelay

    this.mouseenterTimeout = setTimeout(() => {
      if (!this.isOpen) {
        this.isHoverOpened = true
        this.open()
      }
    }, delay)
  }

  onMouseleave() {
    clearTimeout(this.mouseenterTimeout)

    if (!this.isMouseEnabled || !this.isHoverOpened) return

    this.mouseleaveTimeout = setTimeout(() => {
      this.close()
    }, this.hoverCloseDelay)
  }
}
