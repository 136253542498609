import Controller from './controller'

export default class extends Controller {
  static get targets() {
    return [
      'name',
      'email',
      'amount',
      'stripe',
      'paymentElement',
      'linkAuthenticationElement',
      'ref',
      'invoice',
      'location',
      'payButton',
    ]
  }

  setLoading(state = true) {
    this.loading = state
  }

  async setupStripeElements(event) {
    event.preventDefault()
    event.stopPropagation()
    if (this.loading) return
    this.setLoading(true)

    const amount = parseFloat(this.amountTarget.value)
    const name = this.nameTarget.value
    const email = this.emailTarget.value
    const ref = this.refTarget.value
    const location = this.locationTarget.value
    const invoice = this.invoiceTarget.value

    const csrf = this.element.querySelector('[name="CRAFT_CSRF_TOKEN"]')

    if (!email) {
      alert('enter your email')
      this.setLoading(false)
      return
    }

    if (!name) {
      alert('enter your name')
      this.setLoading(false)
      return
    }

    if (!location) {
      alert('enter your location')
      this.setLoading(false)
      return
    }

    if (!ref) {
      alert('enter your child reference')
      this.setLoading(false)
      return
    }

    if (isNaN(amount)) {
      alert('You must enter a number')
      this.setLoading(false)
      return
    }

    if (amount < 5) {
      alert('You must enter a larger amount')
      this.setLoading(false)
      return
    }

    for (const target of ['name', 'email', 'amount', 'ref', 'invoice', 'location']) {
      this[`${target}Target`].setAttribute('disabled', true)
    }

    this.payButtonTarget.parentNode.removeChild(this.payButtonTarget)
    let config = ''

    try {
      config = this.locationTarget.selectedOptions[0].dataset.config || ''
    } catch (e) {}

    const res = await fetch('/actions/buffalo/stripe/create-intent', {
      method: 'POST',
      body: JSON.stringify({
        CRAFT_CSRF_TOKEN: csrf.value,
        amount,
        name,
        email,
        ref,
        location,
        invoice,
        config,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    const json = await res.json()

    const stripe = Stripe(json.pk)
    const elements = stripe.elements({ clientSecret: json?.intent?.client_secret })

    const paymentElementOptions = {
      layout: 'tabs',
    }

    const paymentElement = elements.create('payment', paymentElementOptions)
    const linkAuthenticationElement = elements.create('linkAuthentication', {
      defaultValues: { email },
    })
    linkAuthenticationElement.mount(this.linkAuthenticationElementTarget)
    paymentElement.mount(this.paymentElementTarget)

    const button = document.createElement('button')
    button.setAttribute('type', 'submit')
    button.innerText = 'Complete Payment'
    button.classList.add('button')

    this.stripeTarget.appendChild(button)

    let paymentLoading = false
    this.stripeTarget.addEventListener('submit', async (e) => {
      e.preventDefault()
      if (paymentLoading) return
      paymentLoading = true

      const returnUrl = new URL(window.location)
      returnUrl.pathname = '/parents-area/thanks'

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: returnUrl.toString(),
          receipt_email: email,
        },
      })

      if (error.type === 'card_error' || error.type === 'validation_error') {
        alert(error.message)
      } else {
        alert('An unexpected error occurred.')
      }

      paymentLoading = false
      return false
    })

    return false
  }
}
