import SwiperCore, {
  A11y,
  Mousewheel,
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  Zoom,
} from 'swiper/core'
import Controller from './controller'

SwiperCore.use([A11y, Mousewheel, Navigation, Pagination, Autoplay, Thumbs, Zoom])

export default class extends Controller {
  static targets = ['slides', 'previous', 'next', 'pagination', 'go']

  static options = {
    grabCursor: true,
    loop: true,
    mousewheel: {
      forceToAxis: true,
    },
    preloadImages: false,
    updateOnImagesReady: false,
    slidesPerView: 'auto',
    centeredSlides: true,
    loopFillGroupWithBlank: true,
  }

  initialize() {
    if (this.options.thumbContainer) {
      this.thumbJazz()
    } else {
      this.createSwiper()
    }

    if (this.hasGoTarget) {
      this.swiper.slideTo(this.goTarget.getAttribute('data-index'))
    }
  }

  thumbJazz() {
    const slider = document.querySelector(this.options.thumbContainer)

    this.thumbInterval = setInterval(() => {
      this.sliderController = this.application.getControllerForElementAndIdentifier(
        slider,
        'slider',
      )

      if (this.sliderController) {
        clearInterval(this.thumbInterval)

        this.options.thumbs = {
          swiper: this.sliderController.swiper,
        }

        this.createSwiper()
      }
    }, 50)
  }

  createSwiper() {
    if (this.hasNextTarget) {
      this.options.navigation ||= {}
      this.options.navigation.nextEl = this.nextTarget
    }

    if (this.hasPreviousTarget) {
      this.options.navigation ||= {}
      this.options.navigation.prevEl = this.previousTarget
    }

    if (this.hasPaginationTarget) {
      this.options.pagination ||= {
        clickable: true,
      }
      this.options.pagination.el = this.paginationTarget
    }

    const slidesTarget = this.hasSlidesTarget ? this.slidesTarget : this.element
    this.swiper = new SwiperCore(slidesTarget, this.options)
  }
}
