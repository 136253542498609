import Controller from './controller'
import { transition } from '../transition'
import { disableBodyScroll, enableBodyScroll } from '../utilities'

export default class extends Controller {
  static targets = ['content', 'home', 'panel', 'toggle']

  initialize() {
    this.close = this.close.bind(this)
    this.elementOpenClass = 'is-open'
  }

  connect() {
    this.isOpen = this.element.classList.contains(this.elementOpenClass)

    if (this.isOpen) {
      this.addEvents()
    }
  }

  disconnect() {
    if (this.isOpen) {
      this.removeEvents()
    }
  }

  toggle() {
    if (this.isOpen) {
      this.close()
    } else {
      this.open()
    }
  }

  open() {
    if (this.isOpen) return
    this.isOpen = true

    this.element.classList.add(this.elementOpenClass)
    this.toggleTarget.setAttribute('aria-expanded', true)

    disableBodyScroll()

    if (this.afterClose) {
      this.afterClose.cancel()
    }

    window.setTimeout(() => {
      this.addEvents()
    }, 50)
  }

  close(event) {
    if (
      event &&
      ((event.type === 'keydown' && event.key !== 'Escape') ||
        (event.type === 'click' && this.element.contains(event.target)))
    ) {
      return
    }

    if (!this.isOpen) return
    this.isOpen = false

    this.element.classList.remove(this.elementOpenClass)
    this.toggleTarget.setAttribute('aria-expanded', false)

    enableBodyScroll()

    this.removeEvents()

    this.emit('header:enable', {}, window)
  }

  showPanel(event) {
    event.preventDefault()

    if (!this.panels) {
      this.panels = {}

      this.panelTargets.forEach((panel) => {
        this.panels[panel.dataset[this.panelData]] = panel
      })
    }

    const panel = this.panels[event.currentTarget.dataset[this.panelData]]

    if (this.shownPanel) {
      this.backPanels.push(this.shownPanel)
      this.shownPanel.classList.add(this.panelBackClass)
    }

    this.contentTarget.classList.add(this.contentPanelShownClass)
    panel.classList.add(this.panelShownClass)
    this.shownPanel = panel
  }

  back(event) {
    event.preventDefault()

    this.shownPanel.classList.remove(this.panelShownClass)
    this.shownPanel = this.backPanels.pop()

    if (this.shownPanel) {
      this.shownPanel.classList.remove(this.panelBackClass)
    } else {
      this.contentTarget.classList.remove(this.contentPanelShownClass)
    }
  }

  reset() {
    this.backPanels.forEach((panel) => {
      panel.classList.remove(this.panelShownClass, this.panelBackClass)
    })
    this.backPanels = []

    if (this.shownPanel) {
      this.shownPanel.classList.remove(this.panelShownClass)
      this.shownPanel = null
    }

    this.contentTarget.classList.remove(this.contentPanelShownClass)
  }

  addEvents() {
    window.addEventListener('click', this.close)
    window.addEventListener('keydown', this.close)
  }

  removeEvents() {
    window.removeEventListener('click', this.close)
    window.removeEventListener('keydown', this.close)
  }
}
