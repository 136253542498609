import Controller from './controller'
import { parseJson } from '../utilities'

export default class extends Controller {
  static get targets() {
    return ['item']
  }

  static get options() {
    return {
      // Delay in ms between animating items in. Can be overridden per element by setting delay in
      // its data attribute, e.g. `data-visible-stagger="50"`
      staggerInterval: 83,

      // Options passed to `IntersectionObserver`. Can be overridden per element by setting options
      // in its data attribute, e.g. `data-visible='{ "threshold": 1, "rootMargin": "-50px" }'`
      // Available options: https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver/IntersectionObserver#Parameters
      intersectionObserverOptions: {
        threshold: 0.1,
      },
    }
  }

  initialize() {
    this.checkIntersection = this.checkIntersection.bind(this)
    this.checkEntryIntersection = this.checkEntryIntersection.bind(this)

    this.isPaused = true
    this.queue = []
    this.observers = {}
    this.timeouts = new WeakMap()

    this.observeItems()
    this.play()

    this.element.addEventListener('visible:reset', this.reset.bind(this))
  }

  play() {
    this.isPaused = false
    this.playInterval = setInterval(() => {
      if (this.queue.length > 0) {
        this.show(this.queue.shift())
      } else {
        this.pause()
      }
    }, this.options.staggerInterval)
  }

  pause() {
    this.isPaused = true
    clearInterval(this.playInterval)
  }

  observeItems(elements = this.itemTargets) {
    elements.forEach(this.observeItem.bind(this))
  }

  observeItem(item) {
    const settings = item.dataset.visible
    let options = this.options.intersectionObserverOptions

    if (settings) {
      options = Object.assign({}, options, parseJson(settings))
    }

    // Re-use existing IntersectionObserver when settings are the same
    if (!this.observers[settings]) {
      this.observers[settings] = new IntersectionObserver(this.checkIntersection, options)
    }

    this.observers[settings].observe(item)
  }

  checkIntersection(entries) {
    entries.forEach(this.checkEntryIntersection)
  }

  checkEntryIntersection(entry) {
    const element = entry.target

    if (entry.isIntersecting) {
      this.staggeredShow(element)
    } else {
      this.removeFromQueue(element, true)
    }
  }

  staggeredShow(element) {
    if (element.dataset.visibleStagger) {
      const delay = Number(element.dataset.visibleStagger)
      const timeout = setTimeout(() => {
        this.show(element)
        this.timeouts.delete(element)
      }, delay)

      this.timeouts.set(element, timeout)
    } else {
      this.queue.push(element)

      if (this.isPaused) {
        this.play()
      }
    }
  }

  show(element) {
    element.dataset.isVisible = true
    this.observers[element.dataset.visible].unobserve(element)

    element.classList.add('is-visible')
    this.emit('visible:show', {}, element)

    if (element.tagName === 'VIDEO') {
      element.play()
    }
  }

  hide(element) {
    delete element.dataset.isVisible
    this.observeItem(element)

    element.classList.remove('is-visible')
    this.emit('visible:hide', {}, element)
  }

  reset(ev) {
    const element = ev.target

    if (element.dataset.isVisible) {
      this.hide(element)
    } else {
      this.removeFromQueue(element)
    }
  }

  removeFromQueue(element, show = false) {
    const index = this.queue.indexOf(element)
    if (index > -1) {
      this.queue.splice(index, 1)

      if (show) {
        this.show(element)
      }
    } else if (this.timeouts.has(element)) {
      clearTimeout(this.timeouts.get(element))
      this.timeouts.delete(element)

      if (show) {
        this.show(element)
      }
    }
  }
}
