import Controller from './controller'
import dataLayer from '../helpers/datalayer'

export default class extends Controller {
  static get targets() {
    return ['nursery']
  }

  initialize() {
    this.go = this.go.bind(this)
  }

  get nursery() {
    if (!this.hasNurseryTarget) return

    return this.nurseryTarget.value
  }

  go(event) {
    // console.log(event.currentTarget.getAttribute('data-datalayer-event'))
    dataLayer({
      event: event.currentTarget.getAttribute('data-datalayer-event'),
      nursery: null,
    })
  }

  gtmLoaded() {
    return !!window['google_tag_data']
  }

  shouldProceed(id) {
    return id === undefined || id.split('-')[0] == 'G'
  }

  eventEmailClick(event) {
    if (!this.gtmLoaded()) {
      return true
    }

    event.preventDefault()
    dataLayer({
      event: 'email_click',
      nursery: null,
      eventCallback: (id) => {
        if (this.shouldProceed(id)) {
          window.location = event.target.href
        }
      },
      eventTimeout: 2000,
    })
  }

  eventPhoneClick(event) {
    if (!this.gtmLoaded()) {
      return true
    }

    event.preventDefault()
    dataLayer({
      event: 'phone_click',
      nursery: null,
      eventCallback: (id) => {
        if (this.shouldProceed(id)) {
          window.location = event.target.href
        }
      },
      eventTimeout: 2000,
    })
  }

  eventBookAVisit(event) {
    if (!this.gtmLoaded()) {
      return true
    }

    event.preventDefault()
    dataLayer({
      event: 'book_a_visit',
      nursery: this.nursery,
      eventCallback: (id) => {
        if (this.shouldProceed(id)) {
          event.target.submit()
        }
      },
      eventTimeout: 2000,
    })
  }

  eventMakeAPayment() {
    dataLayer({
      event: 'make_a_payment',
      nursery: null,
    })
  }

  eventFeeDownload(event) {
    if (!this.gtmLoaded()) {
      return true
    }

    event.preventDefault()
    dataLayer({
      event: 'fee_download',
      nursery: null,
      eventCallback: (id) => {
        if (this.shouldProceed(id)) {
          window.location = event.target.href
        }
      },
      eventTimeout: 2000,
    })
  }
}
