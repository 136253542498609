let elementIdIndex = 0

export const elementId = () => {
  elementIdIndex += 1
  return `_element_${elementIdIndex}`
}

// https://stackoverflow.com/a/27728417
export const youtubeUrlToId = (url) => {
  const regExp =
    /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]+).*/
  const match = url.match(regExp)
  return match ? match[1] : null
}

let _scrollbarWidth
export const scrollbarWidth = () => {
  if (_scrollbarWidth == null) {
    _scrollbarWidth = window.innerWidth - document.body.offsetWidth
  }

  return _scrollbarWidth
}

export const disableBodyScroll = () => {
  const bodyScrollbarWidth = scrollbarWidth()

  if (bodyScrollbarWidth) {
    document.documentElement.style.setProperty('--scrollbar-gap', `${bodyScrollbarWidth}px`)
  }

  document.body.style.overflow = 'hidden'
  document.body.style.paddingRight = `${bodyScrollbarWidth}px`
}

export const enableBodyScroll = () => {
  document.body.style.overflow = ''
  document.body.style.paddingRight = ''
  document.documentElement.style.removeProperty('--scrollbar-gap')
}

export const isReducedMotion = () => window.matchMedia('(prefers-reduced-motion: reduce)').matches

let fixedMobileHeaderHeightElement
let fixedMainHeaderHeightElement
export const fixedHeaderHeight = () => {
  if (!fixedMobileHeaderHeightElement) {
    fixedMobileHeaderHeightElement = document.getElementById('fixed-mobile-header-height')
  }

  if (!fixedMainHeaderHeightElement) {
    fixedMainHeaderHeightElement = document.getElementById('fixed-main-header-height')
  }

  return Math.max(
    fixedMobileHeaderHeightElement.offsetHeight,
    fixedMainHeaderHeightElement.offsetHeight,
  )
}

export const scrollToPosition = ({ target, top, left, behavior, includeHeaderOffset }) => {
  target ||= window
  top ||= 0
  left ||= 0
  behavior ||= 'smooth'

  if (behavior === 'smooth' && isReducedMotion()) {
    behavior = 'auto'
  }

  target.scrollTo({
    top,
    left,
    behavior,
  })
}

export const parseJson = (json) => {
  if (!json) return {}

  let parsed

  try {
    parsed = JSON.parse(json)
  } catch (error) {
    parsed = {}
  }

  return parsed
}
