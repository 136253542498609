import Controller from './controller'

export default class extends Controller {
  async initialize() {
    const url = this.data.get('url')
    const response = await fetch(url)

    if (response.ok) {
      const content = await response.text()
      this.element.outerHTML = content
    }
  }
}
