import Controller from './controller'

export default class extends Controller {
  async initialize() {
    let Pikaday = await import(/* webpackChunkName: "pikaday" */ 'pikaday')
    Pikaday = Pikaday.default

    this.picker = new Pikaday({
      field: this.element,
      format: 'DD-MM-YY',
      minDate: new Date(),
    })
  }
}
