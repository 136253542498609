import Controller from './controller'

export default class extends Controller {
  static targets = ['item']

  initialize() {
    this.elementOpenClass = 'has-open-dropdown'
    this.elementOpeningClass = 'has-opening-dropdown'

    this.element.addEventListener('dropdown:open', (ev) => {
      const prevDropdown = this.openDropdown
      this.openDropdown = ev.detail.dropdown

      if (prevDropdown) {
        prevDropdown.close()
      } else {
        this.element.classList.add(this.elementOpeningClass)

        this.afterOpenTimeout = setTimeout(() => {
          this.setQuickSwitchMode()
        }, 350)
      }
    })

    this.element.addEventListener('dropdown:close', (ev) => {
      // If a new dropdown hasn't been opened
      if (ev.detail.dropdown === this.openDropdown) {
        this.openDropdown = null
        this.element.classList.remove(this.elementOpeningClass)

        clearTimeout(this.afterOpenTimeout)
        this.unsetQuickSwitchMode()
      }
    })
  }

  // Reduce hover delay when switching from an already open dropdown to another
  setQuickSwitchMode() {
    if (this.quickSwitchMode) return
    this.quickSwitchMode = true

    this.element.classList.add(this.elementOpenClass)

    this.itemTargets.forEach((_item, i) => {
      const dropdown = this.dropdownInstance(i)

      if (dropdown) {
        dropdown.quickSwitchMode = true
      }
    })
  }

  unsetQuickSwitchMode() {
    if (!this.quickSwitchMode) return
    this.quickSwitchMode = false

    this.element.classList.remove(this.elementOpenClass)

    this.itemTargets.forEach((_item, i) => {
      const dropdown = this.dropdownInstance(i)

      if (dropdown) {
        dropdown.quickSwitchMode = false
      }
    })
  }

  focus(ev) {
    const right = ev.key === 'ArrowRight'
    const left = ev.key === 'ArrowLeft'

    if (!right && !left) {
      return
    }

    const index = this.itemTargets.indexOf(ev.currentTarget)
    const nextIndex = index + (right ? 1 : -1)

    if (this.itemTargets[nextIndex]) {
      const currentDropdown = this.dropdownInstance(index)
      const nextDropdown = this.dropdownInstance(nextIndex)

      if (nextDropdown) {
        // Focus dropdown
        nextDropdown.toggleTarget.focus()
      } else {
        // Focus link
        this.itemTargets[nextIndex].focus()
      }

      // Keep current open state when moving focus to next dropdown
      if (currentDropdown && currentDropdown.isOpen) {
        currentDropdown.close()

        if (nextDropdown) {
          nextDropdown.open()
        }
      }
    }
  }

  // Get a `dropdown_controller` instance from a dropdown element
  dropdownInstance(index) {
    if (!this.dropdownInstances) this.dropdownInstances = {}

    if (!this.dropdownInstances[index]) {
      this.dropdownInstances[index] = this.application.getControllerForElementAndIdentifier(
        this.itemTargets[index],
        'dropdown',
      )
    }

    return this.dropdownInstances[index]
  }
}
