import A11yDialog from 'a11y-dialog'
import Controller from './controller'
import { disableBodyScroll, enableBodyScroll } from '../utilities'

export default class Modal extends Controller {
  static get options() {
    return {
      destroyOnClose: false,
    }
  }

  initialize() {
    this.destroy = this.destroy.bind(this)
    this.onDialogHide = this.onDialogHide.bind(this)

    this.openClass = 'is-open'
    this.dialog = new A11yDialog(this.element)

    // Store this modal so it can be reopened if a modal-link with the same ID is clicked
    this.id = this.data.get('id')
    Modal.instances[this.id] = this

    if (!this.element.hasAttribute('data-modal-closed')) {
      this.open()
    }

    this.hashCheck()
  }

  hashCheck() {
    const hash = window.location.hash.substr(1)

    if (this.id === hash) {
      this.open()
    }
  }

  destroy() {
    this.element.parentNode.removeChild(this.element)
  }

  open() {
    if (this.isOpen) return
    this.isOpen = true

    // Add open class after a delay, otherwise transitions may not work (if modal has just been added to the DOM)
    requestAnimationFrame(() => {
      this.element.classList.add(this.openClass)
    })

    disableBodyScroll()

    // Delay needed after modal is added to the DOM, or A11yDialog may not set focus element & hitting esc won't close modal
    setTimeout(() => {
      this.dialog.show()
      this.dialog.on('hide', this.onDialogHide)
    }, 50)
  }

  close() {
    this.dialog.hide()
  }

  onDialogHide() {
    if (!this.isOpen) return
    this.isOpen = false

    if (this.options.destroyOnClose) {
      Modal.instances[this.id] = null

      // Wait until transition ends before removing modal
      setTimeout(this.destroy, 350)
    }

    // Add class to trigger hiding transition
    this.element.classList.remove(this.openClass)
    this.dialog.off('hide', this.onDialogHide)

    window.removeEventListener('keydown', this.onKeydown)

    enableBodyScroll()
  }
}

Modal.instances = {}
