import Controller from './controller'

export default class Visible extends Controller {
  static targets = ['template']

  initialize() {
    this.checkIntersection = this.checkIntersection.bind(this)
    this.checkEntryIntersection = this.checkEntryIntersection.bind(this)

    this.observer = new IntersectionObserver(this.checkIntersection, {
      rootMargin: '50% 0px'
    })
    this.observer.observe(this.element)
  }

  checkIntersection(entries) {
    entries.forEach(this.checkEntryIntersection)
  }

  checkEntryIntersection(entry) {
    const element = entry.target

    if (entry.isIntersecting) {
      this.show(element)
    }
  }

  show() {
    this.observer.disconnect()

    this.element.innerHTML = this.templateTarget.innerHTML
  }
}
